<template>
    <div class="container-box">
        <div class="top-wrap">
            <div class="formItem">
                <p class="title"><b>信息填写</b></p>
                <div class="itemWrap">
                    <div class="listItem">
                        <div class="item iTitle">本次报名人数</div>
                        <div class="item"><Input @monitor="getMonitor" type="bm" /></div>
                        <!-- <div class="item">
							 <el-input v-model="text" placeholder="请输入" />
						</div> -->
                        <div class="item iTitle">本次报名组数</div>
                        <div class="item"><Input @monitor="getMonitor" type="zm" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">竞赛单人费用</div>
                        <div class="item"><Input @monitor="getMonitor" type="drfy" /></div>
                        <div class="item iTitle">本次上传应缴费</div>
                        <div class="item"><Input @monitor="getMonitor" type="yjf" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">发票内容</div>
                        <div class="item"><Input @monitor="getMonitor" type="lx" /></div>
                        <div class="item iTitle">发票抬头</div>
                        <div class="item"><Input @monitor="getMonitor" type="tt" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">税号</div>
                        <div class="item"><Input @monitor="getMonitor" type="fp1" /></div>
                        <div class="item iTitle">发票备注栏信息</div>
                        <div class="item"><Input @monitor="getMonitor" type="fp2" /></div>
                    </div>
                    <div class="listItem">
                        <!-- <div class="item iTitle">纸质发票/电子发票</div> -->
                        <div class="item iTitle">电子发票接收邮箱</div>
                        <div class="item" style="border-bottom: 1px solid #ecedf0;"><Input ref="emailInput" @monitor="getMonitor" type="fp4" /></div>
                        <div class="item iTitle"></div>
                        <div class="item" style="border-bottom: 1px solid #ecedf0;">
                            <!-- <Input @monitor="getMonitor" type="fp3" /> -->
                        </div>
                    </div>
                    <!-- <div class="lastItem">
                        <div class="item iTitle">纸质发票邮寄地址</div>
                        <div class="item"><Input @monitor="getMonitor" type="qt1" /></div>
                        <div class="item iTitle"></div>
                        <div class="item"></div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="bottom-wrap">
            <div class="left-wrap">
                <div class="formItem">
                    <p class="title"><b>支付方式</b></p>
                    <div class="upload">
                        <el-upload ref="upload" class="avatar-uploader" action="" :show-file-list="false"
                            :on-remove="handleRemove" :on-change="onChange" :on-success="handleUploadSuccess"
                            :before-upload="beforeAvatarUpload" :http-request="httpRequest" :file-list="fileList">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </div>
                    <span class="upload-title">点击上传支付凭证</span>
                </div>
                <div class="payment" v-if="(!this.formItem.invoiceType && !info && !noInfo)">
                    <p>支付说明与汇款信息：</p>
                    <p>1、请先在上面选择您的开票内容后，按照对应的付款方式进行支付。</p>
                    <p>2、注意支付时须备注学校全称+参赛人数，并截取支付成功截图或能证明已汇款项的截图，点击左侧进行上传。</p>
                    <p>3、竞赛方将于1-2个工作日内审核完成。</p>
                    <p><el-link type="primary" @click="Download">4、大唐杯参赛委托代收证明下载</el-link></p>
                    <p style="color: red;">注：<b>报名费</b>仅支持转账汇款支付，<b>技术服务费/会议费/会务费和不开发票</b>支持公务卡/信用卡等的微信扫码支付（发票可备注栏加备注信息）。</p>
                </div>
                <div class="payment" v-if="info">
                    <p>支付说明与汇款信息：</p>
                    <p>如果您的开票方式选择的是报名费,请将参赛费用汇入下列账户，汇款时须备注学校全称+参赛人数，并截取汇款成功截图或能证明已汇款项的截图，点击左侧进行上传。</p>
                    <p>收款账户：中教新媒（北京）文化传媒有限公司</p>
                    <p>开户行及帐号: 招商银行北京分行营业部 1109 0962 7910 109</p>
                    <p><el-link type="primary" @click="Download">大唐杯参赛委托代收证明下载</el-link></p>

                </div>
                <div class="payment" v-if="noInfo">
                    <p>支付说明与汇款信息：</p>
                    <p>1、如果您的开票方式选择的是<b>技术服务费/会务费/会议费或不开发票</b>，请进行微信扫码支付。</p>
                    <p>2、<b>支持公务卡/信用卡</b>扫码缴费，支付时须备注<b>学校全称+参赛人数</b>。</p>
                    <p>3、缴费成功后，截图并保存，点击左侧进行上传缴费凭证。</p>
                    <p><el-link type="primary" @click="Download">4、大唐杯参赛委托代收证明下载</el-link></p>
                    <p style="color: red;">注：发票备注栏可备注大赛全称报名费（如：大唐杯全国大学生新一代信息通信技术大赛原大唐杯全国大学生移动通信5G技术大赛报名费）</p>
                </div>
            </div>
            <div class="right-wrap" v-if="noInfo">
                <img src="../../assets/images/wchat3.png">
                <p>扫描二维码进行支付</p>
            </div>
            <div class="center-wrap">
                <el-button @click="save">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Input from './textInput.vue';
import { Plus } from '@element-plus/icons-vue';
import { httpRequest } from '@/tools/common.js'
import { userInfo } from "api/apis.js";
import { ElMessage } from 'element-plus'
import {Debounce} from '@/tools/common.js'
import { log } from 'console';
export default {
    name: 'Step',
    components: {
        Input,
        Plus
    },
    data() {
        return {
            text: '',
            imageUrl: '',
            dialogImageUrl: '',
            dialogVisible: true,
            info: false,
            noInfo: false,
            time: new Date(),
            fileList: [],
            // url: '',
            pay_pic_path: '',
            formItem: {
                number: '', //报名人数
                // groupNum: '',
                sFeeNum: '',
                money: '', //应缴费
                team_num:'',
                invoice_content:'',//发票类型
                invoice_title: '', //发票抬头
                invoiceHeader: '',
                invoice_num: '',//税号
                invoice_way:'',
                memo: '',//备注
                invoice4: '',
                invoice_address: '',//发票邮寄地址
                other2: '',
            },
            is_need_bill:'',
            options: [
                {
                    value: 'Option1',
                    label: '微信',
                },
                {
                    value: 'Option2',
                    label: '支付宝',
                },
            ],
            query:{}
        }
    },
    created() {
       this.formItem.number = this.$route.query.num
       this.query = this.$route.query
       const {total_Obj} =this.$route.query;
        const {team_nums,nums,s_fee} = JSON.parse(total_Obj);
        this.formItem.number = nums;
        this.formItem.team_num = team_nums;
        this.formItem.sFeeNum = s_fee;
        this.formItem.money = Number(nums)*Number(s_fee);
    },
    methods: {
        //获取回显信息
        getMonitor(val, type,isFormat) {
            if (type == 'bm') {
                this.formItem.number = `${val}`
            } else if (type == 'zm') {
                this.formItem.team_num = `${val}`
            } else if (type == 'drfy') {
                this.formItem.sFeeNum = `${val}`
            } else if (type == 'yjf') {
                this.formItem.money = `${val}`
            } else if (type == 'lx') {
                if (!val) {

                    this.info = false
                    this.noInfo = false
                }
                if (`${val}` == '现代服务*报名费') {
                    this.info = true
                    this.noInfo = false
                    this.is_need_bill='是'
                }
                if (`${val}` == '不开发票' || `${val}` == '现代服务*技术服务费' ||`${val}` == '会展服务*会务费' || `${val}` == '会展服务*会议费') {
                    this.noInfo = true
                    this.info = false
                }
                if(`${val}` == '现代服务*技术服务费') {
                    this.is_need_bill='是'
                }
                if(`${val}` == '不开发票') {
                    this.is_need_bill='否'
                }
                this.formItem.invoice_content = `${val}`
            }
            else if (type == 'tt') {
                this.formItem.invoice_title = `${val}`
            }
            else if (type == 'fp1') {
                this.formItem.invoice_num = `${val}`
            }
            else if (type == 'fp2') {
                this.formItem.memo = `${val}`
            }
            else if (type == 'fp3') {
                this.formItem.invoice_way = `${val}`
            }
            //电子邮箱
            else if (type == 'fp4') {
                this.formItem.invoice_address = `${val}`
                sessionStorage.setItem('FP4',`${val}`)
            }
            //发票邮寄地址
            // else if (type == 'qt1') {
            //     this.formItem.invoice_address = `${val}`
            //     sessionStorage.setItem('QT1',`${val}`)
            // }

        },
        //提交缴费
        save:Debounce(function() {
            console.log(this.formItem, '@formItem');
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
            if(sessionStorage.getItem('FP4')) {
                let val = sessionStorage.getItem('FP4')
                if(!mailReg.test(val)) {
                    ElMessage({
                    message: "请输入正确的邮箱格式",
                    type: "error",
                    });
                    return 
                }
            }
            if(this.formItem.invoice_content =='') {
                ElMessage({
                    message: "请选择发票内容",
                    type: "error",
                });
                return
            }
            if(this.formItem.invoice_address == '' && this.formItem.invoice_content !='不开发票') {
                ElMessage({
                    message: "请填写邮箱",
                    type: "error",
                    });
                    return
            }
            if (this.pay_pic_path == '') {
                ElMessage({
                    message: "请上传支付凭证",
                    type: "error",
                });
                return
            }
            let team_ids = this.query.team_ids
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            if(this.formItem.invoice_content == '不开发票') {
                this.formItem.invoice_num = ''
                this.formItem.memo = ''
                this.formItem.invoice_address = ''
                this.formItem.invoice_way =''
                this.formItem.invoice_title = ''
            } 
            dataobj.append("user_id", uid);//用户id
            dataobj.append("number", this.formItem.number);//报名人数
            dataobj.append("team_num", this.formItem.team_num);//组数
            dataobj.append("money", this.formItem.money);//费用
            dataobj.append("is_need_bill", this.is_need_bill);//
            dataobj.append("invoice_title", this.formItem.invoice_title);//发票抬头
            dataobj.append("invoice_num", this.formItem.invoice_num);//税号
            dataobj.append("invoice_content", this.formItem.invoice_content);
            dataobj.append("invoice_way", '电子发票');
            dataobj.append("memo", this.formItem.memo);
            dataobj.append("invoice_address", this.formItem.invoice_address);
            dataobj.append("pay_pic_path", this.pay_pic_path); //图片路径
            dataobj.append("team_ids", JSON.stringify(team_ids)); //团队id
            userInfo.invoice(dataobj).then(res => {
                if (!res) {
                    return
                } else {
                    ElMessage({
                        message: "提交成功",
                        type: "success",
                    });
                    this.$router.push('/Teachers');
                }
            })
        },1000),
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        // 上传的方法
        onChange(file, fileList) {
            console.log(file, 'file', fileList, 'Filest');
            this.fileList = fileList
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList, 'response, file, fileList')
        },
        handleRemove(e, fileList) {
            this.fileList = fileList;
        },
        beforeAvatarUpload(file) {
            // 文件大小校验
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.error('文件大小不能超过 10M !');
            }
            return isLt2M;
        },

        //回调接收上传成功的参数
        getHttpRelut(res) {
            //业务逻辑
            this.imageUrl = res.url;
            this.pay_pic_path = res.url;
        },
        //上传时的oss 
        // user_register// 用户注册
        // sign_up_audit// 审核 
        httpRequest(file) {
            httpRequest(file, "sign_up_audit", this.getHttpRelut);
        },
        // 文件下载
        Download(){
				window.open('./static/ZhenM/第十届大唐杯参赛费代收证明.pdf')
		},
    },
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    }
}
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 3.14rem;
    height: 3rem;
    display: block;
}
</style>
<style lang="scss" scoped>
:deep(.el-select .el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
    border: none;

    .el-input__inner {}
}

:deep(.el-textarea__inner) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    resize: none;
}

// :deep(.el-select-dropdown){
// 	box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
// 	border: none;
// }
// :deep(.el-select) {
// 	box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
//     border: none;
// }
// :deep(.el-input__inner){
//     border: none;
//     box-shadow: none;
// }  
:deep(.el-upload) {
    width: 3.14rem !important;
    height: 3.83rem !important;
}

:deep(.el-select) {
    margin-bottom: 0.2rem;
    //     -webkit-box-shadow:0px 3px 3px #c8c8c8 ;

    // -moz-box-shadow:0px 3px 3px #c8c8c8 ;

    // box-shadow:0px 3px 3px #c8c8c8 ;

}

.payment :deep(.el-input__wrapper) {
    background-color: #F5F7FA;
    -webkit-box-shadow: 0px 0.06rem 3px #c8c8c8;

    -moz-box-shadow: 0px 0.06rem 3px #c8c8c8;

    box-shadow: 0px 0.06rem 3px #c8c8c8;

}

:deep(.el-button) {
    width: 1.71rem;
    height: 0.45rem;
    background-color: #436E8A;
    color: white;
    font-size: 0.24rem;
}

.avatar-uploader {
    border: 1px dashed #dcdfe6;
}

.avatar-uploader:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload {
    border: 1px dashed #dcdfe6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

@import './index.scss';
</style>